import React from 'react';
import Nav from '../containers/Nav';
import './HeroHeader.scss';

const HeroHeader = () => {

    return (
    <header className="container-fluid hero-header_container">
        <div className="row display-flex">
            <div className="col-12">
                <div className="hero-header_logo">
                    <img 
                    src={require('../assets/logo-black.png?v1')} 
                    alt="Orsini Design"
                    className="hero-header_logo--img"
                />
                </div>
                <Nav />
            </div>
        </div>
    </header>
    );

}

export default HeroHeader;

