import React from 'react';

const Footer = () => {

    return (
      <footer className="app-footer">
          <div className="container footer_container footer_container-info">
              <div className="row">
                  <div className="col-md-5 col-sm-12 footer_column-bottom">
                      <strong>Orsini Design</strong><br/>
                        19 East 73rd Street<br />
                        New York<br />
                        10021<br />
                        USA
                  </div>
                  <div className="col-md-4 col-sm-12 footer_column-bottom">
                      <strong>follow us </strong><br/>
                        <a href="https://www.instagram.com/alviseorsini"
                            className="footer_container-info-mid footer_social-instagram"
                            title="Orsini Design">@alviseorsini
                        </a>
                  </div>
                  <div className="col-md-3 col-sm-12">
                      <p>&#169; 2020 Orsini Design</p>
                  </div>
              </div>
          </div>
      </footer>
    );

}

export default Footer;