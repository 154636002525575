import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HeroHeader from '../containers/HeroHeader';
import ProductList from '../containers/ProductList';
import Project from '../containers/Project';
import CompanyProfile from '../containers/CompanyProfile';
import Contacts from '../containers/Contacts';
import Error from '../containers/Error';
import Footer from '../containers/Footer';
import Fullscreen from '../containers/Fullscreen';

const App = () => {

  return (
    <>
      <HeroHeader />
      <Switch>
        <Route path="/" component={ProductList} exact />
        <Route path="/interiors/:projectId" component={Project} />
        <Route path="/media" component={Fullscreen} />
        <Route path="/contacts" component={Contacts} />
        <Route path="/about" component={CompanyProfile} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );

}

export default App