import React from 'react';
import './ProductList.scss';

const Contacts = () => {
    return (
      <main className="app-section product_main-section">
        <div className="container">
          <article className="row product_profile-container">
              <div className="col">
                <div className="row">
                  <div className="col-lg-5 d-none d-sm-block">
                    <div className="product_profile-img-frame white-framed">
                      <img alt="Orsini Design" src={`${process.env.ASSETS_URL}ny03.jpg`} />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <h1 className="product_profile-body--title">Contacts</h1>
                    <h2 className="product_profile-body--subtitle">Address</h2>
                    <p className="product_profile-body--paragraph">
                    19 East 73rd Street<br />
                    New York<br />
                    10021<br />
                    USA
                    </p>
                    <h2 className="product_profile-body--subtitle">Email</h2>
                    <p className="product_profile-body--paragraph">
                      <a href="mailto:Info@OrsiniDesign.com">Info@OrsiniDesign.com</a><br />
                      Please send me an email and I'll get back to you as soon as I can.
                    </p>
                  </div>
                </div>
              </div>
          </article>
        </div>
      </main>
    );
}

export default Contacts;

