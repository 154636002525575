import React, { useEffect, useRef, useState, RefObject } from 'react';
import * as portfolio from '../data/portfolio.json';
import './ProductList.scss';
import { Link } from 'react-router-dom';

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
        setIsOnScreen(entry.isIntersecting)
      },
      { root: null, rootMargin: "-100px 0px -100px 0px", threshold: 0.5}
    );
    if(ref.current){
      observerRef.current.observe(ref.current);
    }

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

const ProductList = () => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [path, setPath] = useState('');

  const handleClick = (image: any) => {
    setPath(image.path);
    setFullScreen(!isFullScreen);
  }

    return (
        <main className="app-section product_main-section">
          <div className="container">
            {isFullScreen && <div className="product_fullscreen">
                <button onClick={()=> setFullScreen(false)}>back</button>
                <img src={path} alt="Orsini Design" />            
              </div>}
              <article className="row product_profile-container" key="interiors-title">
                <div className="col-lg-12">
                  <h1 className="product_profile-body--bold-title">Interiors</h1>
                </div>
              </article>
              {portfolio &&  portfolio.jobs.map(item => {
                return (
                  <article className="row product_profile-container portfolio" key={item.key}>
                    <div className="col-lg-12">
                      <h1 className="product_profile-body--title">
                        <Link to={`/interiors/${item.jobId}`}
                          className="product_profile-body--link">
                          {item.name}
                        </Link>
                      </h1>
                      <p className="product_profile-body--paragraph">
                        {item.description}
                      </p>
                      <section className="product_profile-body--frame">
                        <div className="product_profile-body--rail">
                          <Rail mediaList={item.media} id={item.key} />
                        </div>
                      </section>
                  </div>
                </article>
                )
              })}
          </div>
        </main>
    );
}

export default ProductList;

interface Media {
  id: string,
  path: string,
}

export const Rail = ({mediaList, id}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);
  const [rail, setRail] = useState([]);

  useEffect(() => {
    if(isOnScreen && rail.length === 0){
      setRail(mediaList);
    }

  }, [isOnScreen]);

  return (
    <>
    <div ref={elementRef} className="rail-placeholder">
      {rail && rail.map(media => {
        return (
        <div className="product_profile-body--img-frame" key={`${id}-${media.id}`}>
          {media.url && 
            <a href={media.url} target="_blank"
              className="product_profile-body--external-link-bttn">
              <img src={require('../assets/external-link.svg?v1')} 
                alt="external link" 
                className="product_profile-body--img-bttn-ico" />
            </a>
          }
          <Link to={{ pathname: '/media', state: { mediaId: media.id} }}
            className="product_profile-body--img-bttn">
            <img src={require('../assets/arrow-fullscreen.svg?v1')} 
              alt="fullscreen" 
              className="product_profile-body--img-bttn-ico" />
          </Link>
          <img alt={media.id} src={`${process.env.ASSETS_URL}${media.id}`} />
        </div>)
      })}
    </div>
    </>
  )
}


