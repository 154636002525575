import React from 'react';
import './ProductList.scss';

const CompanyProfile = () => {
    return (
      <main className="app-section product_main-section">
        <div className="container">
          <article className="row product_profile-container">
            <div className="col">
              <div className="row">
                <div className="col-lg-5 d-sm-block">
                  <div className="product_profile-img-frame">
                      <img src={require('../assets/alvise-orsini.jpg?v1')} alt="Alvise Orsini" className="product_profile-img" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <p className="product_profile-body--paragraph"><strong>Alvise Orsini</strong> founded Orsini Design in London after practising in Paris and in Venice (his hometown), where he studied architecture.
Alvise believes that a house should reflect its owner's personality and lifestyle.&nbsp;
Modern or classical, rigorous or baroque, formal or casual, a house should ooze elegance and style.<br /><br />
Working closely with our clients, surveyors, contractors, lighting and sound engineers Alvise designs and creates homes that look like their owners and are sympathetic to the building they are in.<br /><br />
Alvise is involved in every detail of each project from clients' interviews to mood boards to new floor layout, from soft furnishing to lighting and audio video systems.<br /><br />
In order to avoid the aggravation and stress often associated with redoing a home, Orsini Design only works with highly professional contractors, moving companies, vendors, engineers and flower designers to deliver ready-to-live homes to our clients.<br /><br />
We also offer full consulting services when purchasing period or modern furniture or Artwork.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </main>
    );
}

export default CompanyProfile;

