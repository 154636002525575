import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import './ProductList.scss';
import './Fullscreen.scss';

interface locationState {
    mediaId: string;
}

const Fullscreen = () => {
    const history = useHistory();
    const location = useLocation();
    const [path, setPath] = useState('');

    useEffect(() => {
        if(location.state){
            setPath(`${process.env.ASSETS_URL}${location.state['mediaId']}`);
        }
      }, [location.state]);

    return (
        <main className="app-section product_main-section">
            <div className="container">
                {path && (<div className="media_fullscreen">
                    <button onClick={()=> history.goBack()}
                        className="media_fullscreen--back-bttn"
                        >Back</button>
                    <img src={path} alt="Orsini Design" />            
                </div>)}
            </div>
        </main>
  );
}

export default Fullscreen;

