import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.scss';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(!isOpen);
  }

  const navItems = () => {
    return (
    <div className="row nav-container">
      <div className="col-12 nav_content">
        <ul className="nav_menu-wrapper">
          <li className="nav_menu-item">
            <NavLink to="/">Interiors</NavLink>
          </li>
          <li className="nav_menu-item">
            <NavLink to="/interiors/press">Press</NavLink>
          </li>
          <li className="nav_menu-item">
            <NavLink to="/about">About</NavLink>
          </li>
          <li className="nav_menu-item">
            <NavLink to="/contacts">Contact</NavLink>
          </li>
          <li className="nav_menu-item">
            <NavLink to="/interiors/collaborations">Collaborations</NavLink>
          </li>
        </ul>
      </div>
    </div>
    )
  }

    return (
    <header className="container-fluid">
      <div className="nav_container_controls">
        <button onClick={handleClick}
          className="nav_hamburger-btn">
          <img 
                src={require('../assets/hamburger-menu.svg')} 
                alt="Menu"
                className="nav-icon"
            />
        </button>
      </div>
      {isOpen && <div className="nav_container_mobile">{navItems()}</div>}
      <div className="nav_container_desktop">
        {navItems()}
      </div>
    </header>
    );

}

export default Nav;
