import React from 'react';
import { useParams } from 'react-router-dom';
import * as portfolio from '../data/portfolio.json';
import './ProductList.scss';
import './project.scss';

interface ProjectParams {
  projectId: string;
}

const Project = () => {
  const { projectId } = useParams() as ProjectParams;
  const project = portfolio.jobs.filter(item => item.jobId === projectId);
  return (
      <main className="app-section product_main-section">
        <div className="container">
          {project && project.map(item => (
            <article className="row project_profile-container">
              <div className="col-lg-12" key={item.key}>
              <h1 className="project_profile-body--title">{item.name}</h1>
              <p className="project_profile-body--paragraph">
                {item.description}
              </p>
              <section className="project_profile-body--frame">
                <div className="project_profile-body--rail">
                  {item.media && item.media.map(mediaItem => {
                    return (
                    <div className="project_profile-body--img-frame" key={mediaItem.id}>
                      <img alt={mediaItem.id} src={`${process.env.ASSETS_URL}${mediaItem.id}`} />
                    </div>)
                  })}
                </div>
              </section>
            </div>
            </article>
          ))}
        </div>
      </main>
  );
}

export default Project;

