// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/instagram.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Brand colors */\n/* Dark blue highlights */\nbody {\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n  height: 100vh; }\n\n.app {\n  /*\n  &-section {\n    transform: translateZ(0);\n  }\n  */ }\n  .app-body {\n    flex: 1 0 auto;\n    padding: 0;\n    /*\n    height: 100vh;\n    max-height: 100%;\n    overflow-x: hidden;\n    perspective: 1px;\n    perspective-origin: center top;\n    transform-style: preserve-3d;\n*/ }\n  .app-footer {\n    flex-shrink: 0;\n    background-color: #cac7c2; }\n\n.btn-brand {\n  color: #fff;\n  background-color: #6A158E;\n  border-color: #6A158E; }\n\n.footer_container {\n  padding: 25px 20px 15px 20px;\n  margin: 0 auto; }\n\n.footer_container-info {\n  font-size: 12px;\n  color: #5f6366; }\n  .footer_container-info-mid {\n    font-size: 14px;\n    color: #5f6366; }\n\n.footer_column-bottom {\n  margin-bottom: 15px; }\n\n.footer_social-instagram {\n  font-size: 14px;\n  color: #5f6366; }\n  .footer_social-instagram::before {\n    display: inline-block;\n    content: ' ';\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 28px 28px;\n    background-repeat: no-repeat;\n    height: 28px;\n    width: 28px;\n    line-height: normal;\n    padding-right: 30px;\n    vertical-align: middle; }\n\n/* width */\n::-webkit-scrollbar {\n  width: 4px;\n  height: 8px; }\n\n/* Track */\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 5px grey;\n  border-radius: 10px; }\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #a0a0a0;\n  border-radius: 10px; }\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #265077; }\n", ""]);
// Exports
module.exports = exports;
